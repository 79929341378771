.modal-root__modal {
  pointer-events: auto;
  display: flex;
  z-index: 9999;
  max-height: 100%;
  overflow-y: hidden;
}

.media-modal {
  // https://stackoverflow.com/a/8468131
  @apply w-full h-full absolute inset-0;

  .audio-player.detailed,
  .extended-video-player {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .audio-player {
    max-width: 800px;
    max-height: 600px;
  }

  .extended-video-player {
    width: 100%;
    height: 100%;

    video {
      @apply max-w-full max-h-[80%];
    }
  }

  &__closer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__navigation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    transition: opacity 0.3s linear;
    will-change: opacity;

    * {
      pointer-events: auto;
    }

    &--hidden {
      opacity: 0;

      * {
        pointer-events: none;
      }
    }
  }

  &__nav {
    @apply absolute top-0 bottom-0 my-auto mx-0 box-border flex h-[20vmax] cursor-pointer items-center border-0 bg-black/50 text-2xl text-white;
    padding: 30px 15px;

    @media screen and (max-width: 600px) {
      @apply px-0.5;
    }

    .svg-icon {
      @apply h-6 w-6;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__pagination {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 20px;
    pointer-events: none;
  }

  &__meta {
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
    pointer-events: none;

    &--shifted {
      bottom: 62px;
    }

    a {
      text-decoration: none;
      font-weight: 500;
      color: #fff;

      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__page-dot {
    display: inline-block;
  }

  &__button {
    background-color: #fff;
    height: 12px;
    width: 12px;
    border-radius: 6px;
    margin: 10px;
    padding: 0;
    border: 0;
    font-size: 0;

    &--active {
      @apply bg-accent-500;
    }
  }

  &__close {
    position: absolute;
    right: 8px;
    top: 8px;
    height: 48px;
    width: 48px;
    z-index: 100;
    color: #fff;

    .svg-icon {
      height: 48px;
      width: 48px;
    }
  }
}

.error-modal {
  @apply text-gray-900;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 80vw;
    max-width: 520px;
    max-height: 420px;
    position: relative;
    text-align: center;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 0;
      user-select: text;
    }
  }

  &__footer {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    padding: 25px;

    & > div {
      min-width: 33px;
    }
  }

  &__nav {
    border: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    line-height: inherit;
    height: auto;
    margin: -10px;
    border-radius: 4px;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      @apply text-gray-400;
    }
  }
}

.actions-modal {
  @apply flex-col relative text-gray-400 overflow-hidden w-full max-w-lg m-auto bg-white dark:bg-gray-900 shadow-xl rounded-2xl;
  max-height: calc(100vh - 3rem);

  &__item-label {
    font-weight: 500;
  }

  .dropdown-menu__separator {
    @apply block m-2 h-[1px] bg-gray-200 dark:bg-gray-600;
  }

  &__status {
    @apply overflow-y-auto max-h-[300px];
  }

  ul {
    @apply my-2 flex-shrink-0 overflow-y-auto;
    max-height: calc(100vh - 147px);

    &.with-status { max-height: calc(80vh - 75px); }

    li:not(:empty) {
      a,
      button {
        @apply flex items-center px-4 py-3 text-gray-700 dark:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800 focus:bg-gray-100 dark:focus:bg-primary-800 no-underline text-left;

        &.destructive {
          @apply text-danger-600 dark:text-danger-400;
        }

        .svg-icon:first-child {
          @apply min-w-[1.25rem] w-5 h-5;

          svg {
            stroke-width: 1.5;
          }
        }
      }

      button[type='button'] {
        @apply w-full justify-center text-center;
      }
    }
  }
}

.reply-mentions-modal__accounts {
  display: block;
  flex-direction: row;
  flex: 1;
  overflow-y: auto;
  min-height: 300px;
}

.remote-interaction-modal {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    button {
      align-self: flex-end;
    }
  }

  &__divider {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 -10px;

    &::before,
    &::after {
      @apply border-b border-gray-300 dark:border-gray-600;
      content: '';
      flex: 1;
    }
  }

  @media screen and (max-width: 895px) {
    margin: 0;
    border-radius: 6px;
    height: unset !important;
    width: 440px !important;
  }

  @media screen and (max-width: 480px) {
    width: 330px !important;
  }
}
